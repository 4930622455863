<template>
	<el-dialog class="dialog" title="编辑" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-button type="primary" style="margin: 20px;" @click="addChangqu()">添加厂区</el-button>
			<el-table ref="table" :data="tableData" style="width: 97%; margin:0 20px 20px" row-key="id" border
				:tree-props="{ children: 'child'}" @row-click="expandChange" max-height="700">
				<el-table-column prop="name" label="设备树" width="300">
					<template slot-scope="scope">
						<el-input v-model="scope.row.name" style="width: 80%;"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="sort" label="排序" width="100">
					<template slot-scope="scope" v-if="scope.row.category==3">
						<el-input v-model="scope.row.sort"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="type" label="油品型号" width="300">
					<template slot-scope="scope" v-if="scope.row.category==3">
						<el-input v-model="scope.row.type"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="standard" label="标准等级" width="100">
					<template slot-scope="scope" v-if="scope.row.category==3">
						<el-input v-model="scope.row.standard"></el-input>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="addChejian(scope.row)" v-if="scope.row.category==1">
							添加车间</el-button>
						<el-button type="text" size="small" @click="addShebei(scope.row)" v-if="scope.row.category==2">
							添加设备</el-button>
						<el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button @click="close()">关闭</el-button>
			<el-button type="primary" @click="submit()">保存</el-button>
		</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	import {
		v4
	} from 'uuid'
	export default {
		mixins: [dialogMixin],
		components: {},
		data() {
			return {
				loading: false,
				tableData: [],
			};
		},
		methods: {
			//打开弹窗
			open() {
				this.tableData = [];
				this.get();
			},
			//点击某一行的回调事件
			expandChange(row, index, e) {
				if (index.label != '操作') {
					this.$refs.table.toggleRowExpansion(row);
				}
			},
			//添加厂区
			addChangqu() {
				let newObj = {
					category: 1,
					child: [],
					id: v4(),
					name: "",
					pid: "0"
				}
				this.tableData.push(newObj)
			},
			//添加车间
			addChejian(row) {
				let newObj = {
					category: 2,
					child: [],
					id: v4(),
					name: "",
					pid: row.id,
				};
				row.child.push(newObj);
				this.$refs.table.toggleRowExpansion(row, true);
			},
			//添加设备
			addShebei(row) {
				let newObj = {
					category: 3,
					id: v4(),
					name: "",
					pid: row.id,
					pollutionClass: null,
					sort: row.child.length + 1,
					standard: "",
					type: "",
				};
				row.child.push(newObj);
				this.$refs.table.toggleRowExpansion(row, true);
			},
			//删除
			remove(row) {
				let pid = row.pid;
				let category = row.category;
				//删除厂区
				if (category == 1) {
					//遍历厂区
					for (let i in this.tableData) {
						let item = this.tableData[i];
						if (item.id == row.id) {
							this.tableData.splice(i, 1);
							return;
						}
					}
					//删除车间
				} else if (category == 2) {
					//遍历厂区
					for (let i in this.tableData) {
						let item = this.tableData[i];
						if (item.id == row.pid) {
							//遍历车间
							for (let i2 in item.child) {
								let item2 = item.child[i2];
								if (item2.id == row.id) {
									item.child.splice(i2, 1);
									return;
								}
							}
						}
					}
					//删除设备
				} else if (category == 3) {
					//遍历厂区
					for (let i in this.tableData) {
						let item = this.tableData[i];
						//遍历车间
						for (let i2 in item.child) {
							let item2 = item.child[i2];
							if (item2.id == row.pid) {
								//遍历设备
								for (let i3 in item2.child) {
									let item3 = item2.child[i3];
									if (item3.id == row.id) {
										item2.child.splice(i3, 1);
										return;
									}
								}
							}
						}
					}

				}
			},
			//获取数据
			get() {
				this.loading = true;
				this.$get("/backend-api/data/oil/detect/template/get", null).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.tableData = res.data;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			submit() {
				this.loading = true;
				this.$postData("/backend-api/data/oil/detect/template/save", this.tableData).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.list();
						this.close();
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 1200px;

		}
	}

	.tables {
		margin: 0 24px 10px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid #DBDBDB;
	}

	.tables2 {
		background: #FFFFFF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid #DBDBDB;
		margin: 12px 16px;
	}

	.title {
		height: 45px;
		color: #595959;
		font-size: 14px;
		padding: 10px 15px;
		background: #F0F0F0;

		.title-name {
			font-weight: bold;
			margin-right: 20px;
		}

		.title-remarks {}
	}

	.title2 {
		height: 45px;
		padding: 10px;
		font-size: 14px;
		font-weight: bold;
		color: #262626;
	}

	.table {
		margin: 0 12px 12px;
	}
</style>
