<template>
	<div style="min-height: 100%;background-color: #000000;">
		<div>
			<h3 style="color: #FFFFFF;padding-left: 35px;">稀油站运行状态</h3>
			<table border>
				<tr>
					<th v-for="(item,i) in list1.thead" :key="i">{{item}}</th>
				</tr>
				<tr v-for="(item,b) in list1.tbody" :key="b">
					<td v-for="(item2,index) in item.trow" :key='index'>
						<img style="width: 20px;height: 20px;vertical-align: middle;" src="@assets/osImg/icon/jingbao0.gif"
							v-if="item2.name=='润滑站运行'" />
						<img style="width: 20px;height: 20px;vertical-align: middle;" src="@assets/osImg/icon/jingbao-1.png"
							v-else-if="item2.num==0" />
						<img style="width: 20px;height: 20px;vertical-align: middle;" src="@assets/osImg/icon/jingbao3.gif"
							v-else />
						<span>
							{{item2.name}}
						</span>
					</td>
				</tr>
			</table>
		</div>
		<div>
			<h3 style="color: #FFFFFF;padding-left: 35px;">含水量实时检测数据</h3>
			<table border>
				<tr>
					<th v-for="(item,l) in list2.thead" :key='l'>{{item}}</th>
				</tr>
				<tr v-for="(item,c) in list2.tbody" :key='c'>
					<td v-for="(item2,index) in item.trow" :key='index' @click="openTuPu(item2)" style="cursor: pointer;">
						<span>
							{{item2.num}}
						</span>
					</td>
				</tr>
			</table>
		</div>
		
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" v-hasPerm="'1554311500775165952'">
						在线添加
					</el-button>
					<el-button type="primary" size="medium" @click="downloadMobal()" style="margin-right: 8px;" v-hasPerm="'1554311762063527936'">
						模板下载
					</el-button>
					<el-upload style="display: inline-block;margin-right: 10px;" action="" :before-upload="BeforeUpload"
						:http-request="uploadExcel" :show-file-list="false" :file-list="fileList" :limit="1"
						accept=".xls,.xlsx" v-hasPerm="'1554311941793648640'">
						<el-button type="primary" size="medium">导入excel</el-button>
					</el-upload>
					<el-button type="primary" size="medium" @click="equipManage()" v-hasPerm="'1554312034261274624'"
						style="margin-right: 8px;">
						设备管理
					</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border max-height="500"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="name" align="center" label="检修名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="time" align="center" label="检测时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="detectNum" align="center" label="检测总数" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="pollutionNum" align="center" label="污染度超标数" show-overflow-tooltip>
							</el-table-column>
							<el-table-column align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)" v-hasPerm="'1554312175223443456'">
										查看
									</el-button>
									<el-button type="text" @click="edit(scope.row)" v-hasPerm="'1554312298217213952'">
										编辑
									</el-button>
									<el-button type="text" @click="remove(scope.row)" v-hasPerm="'1554312399199277056'">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- <chats ref="chats" :row="tbRow"></chats> -->
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
		<insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
		<equipManage :isShow.sync="showEquipManageDialog" :dialogObj="equipManageObj"></equipManage>
	</div>
</template>

<script>
	import edit from "./youyeDataInsert/edit.vue"
	import insert from "./youyeDataInsert/insert.vue"
	import look from "./youyeDataInsert/look.vue"
	import equipManage from "./youyeDataInsert/equipManage.vue"
	// import chats from "../../monitoring/component/chats.vue";
	export default {
		name: "youye",
		components: {
			// chats,
			edit,
			insert,
			look,
			equipManage
		},
		data() {
			return {
				list1: {},
				list2: {},
				t: null,
				searchParm: {
					size: 10,
					current: 1
				},
				newFile: new FormData(),
				fileList: [],
				showEditDialog: false,
				showLookDialog: false,
				showInsertDialog: false,
				showEquipManageDialog: false,
				insertObj: {},
				editObj: {},
				lookObj: {},
				equipManageObj: {},
				tableData: {
					records: [],
				},
				tbRow: null,
			}
		},
		methods: {
			get() {
				this.$get("backend-api/web/screen/palSit", null).then((res) => {
					if(res.code != 1000){
						this.$message.error(res.msg);
						return
					}
					this.list1 = res.data
				});
			},
			get2() {
				this.$get("backend-api/web/screen/oilSit", null).then((res) => {
					if(res.code != 1000){
						this.$message.error(res.msg);
						return
					}
					this.list2 = res.data
				});
			},
			//打开设备管理弹窗
			equipManage() {
				this.equipManageObj = {};
				this.showEquipManageDialog = true;
			},
			//打开图谱
			openTuPu(item) {
				this.Bus.$emit("clickDanti",item)
			},
			// 打开图表弹框
			// getChats(item) {
			// 	this.$refs.chats.dialogVisible = true;
			// 	this.$refs.chats.fullscreen = true;
			// 	this.tbRow = item;
			// },
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//上传前校验
			BeforeUpload(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.xls' && fileType != '.xlsx') {
					this.$message.error('上传文件只能是 excel 文件格式!');
				} else if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			uploadExcel() {
				this.loading = true;
				this.$uploadWithName(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.$post("/backend-api/data/oil/detect/save", {
							path: res.data
						}).then((res2) => {
							if (res2.code == 1000) {
								this.list();
								this.loading = false;
								this.newFile = new FormData();
								this.fileList = [];
								this.$message.success(res2.msg);
							} else {
								this.loading = false;
								this.$message.error(res2.msg);
							}
						});
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			downloadMobal() {
				//获取下载路径
				this.$get("/backend-api/data/oil/detect/template", null).then((res) => {
					if (res.code == 1000) {
						//下载
						this.$get("/base-api/file/download", {
							path: res.data
						}).then((res2) => {
							if (res2.code == 1000) {
								var bstr = atob(res2.data), //解析 base-64 编码的字符串
									n = bstr.length,
									u8arr = new Uint8Array(n); //创建初始化为0的，包含length个元素的无符号整型数组
								while (n--) {
									u8arr[n] = bstr.charCodeAt(n); //返回字符串第一个字符的 Unicode 编码
								}
								let index = res.data.lastIndexOf("/");
								let filename = res.data.substring(index + 1, res.data.length)
								const blob = new Blob([u8arr]); // 创建blob对象
								const elink = document.createElement("a"); // 创建的标签
								elink.download = filename;
								elink.style.display = "none";
								elink.href = URL.createObjectURL(blob); // 创建url
								document.body.appendChild(elink); // 把 创建的标签追加到body里
								elink.click();
								URL.revokeObjectURL(elink.href); // 释放URL 对象
								document.body.removeChild(elink); // 移除标签
							} else {
								this.$message.error(res2.msg);
							}
						});
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//展示数据
			list() {
				this.$get("/backend-api/data/oil/detect/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开查看弹窗
			look(row) {
				this.lookObj = {};
				this.lookObj["id"] = row.id;
				this.showLookDialog = true;
			},
			//打开新增弹窗
			insert() {
				this.insertObj = {};
				this.showInsertDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.showEditDialog = true;
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除记录", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/backend-api/data/oil/detect/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			clickDanti(item) {
				this.$emit("clickDanti", item);
			},
		},
		mounted() {
			this.get();
			this.get2();
			this.list();
			this.t = setInterval(() => {
				this.get();
				this.list();
			}, 60000);
			this.t = setInterval(this.get2, 30000);
		},
		beforeDestroy() {
			clearInterval(this.t);
		}
	}
</script>

<style lang="scss" scoped>
	@import "@style/userCenter.scss";

	table {
		margin: 30px;
		display: inline-block;
		width: 96%;
		overflow: auto;
		tr {
			display: flex;

			th {
				background-color: #404040;
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}

			td {
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}
		}
	}

	.tableModule {
		flex: 1;
		background-color: #1f1c1f;
		padding: 12px;
		display: flex;
		flex-direction: column;

		.title {
			height: 44px;
			font-size: 16px;

			.title-left {
				display: inline-block;
				margin-top: 8px;
				color: #ffffff;

				i {
					margin-right: 7px;
				}
			}

			.title-right {
				float: right;
			}
		}

		.tableContainer {
			flex: 1;
			display: flex;
			flex-direction: column;

			.table {
				flex: auto;
				position: relative;

				>div {
					position: relative;
				}

				::v-deep .cell {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					word-break: break-all;
				}

				::v-deep tbody {
					>tr {
						>td {
							background-color: #1f1c1f !important;
							border-color: #3e3b3f !important;
							color: #FFFFFF !important;
						}
					}
				}

				::v-deep thead {
					>tr {
						>th {
							background-color: #423f42 !important;
							border-color: #4a474a !important;
							color: #FFFFFF !important;
						}
					}
				}

				.el-table--border {
					border-color: #4f4c50 !important;
				}

				.el-table--border::before,
				.el-table--border::after {
					background-color: #4f4c50 !important;
				}
			}

			::v-deep .el-pagination {

				button,
				.el-pager li,
				span {
					color: #ffffff;
					background-color: #1f1c1f !important;
				}

				.el-pager li.active {
					color: #409EFF;
				}
			}
		}
	}
</style>
