<template>
	<el-dialog class="dialog" title="编辑" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" style="padding-bottom: 5px;">
				<el-row>
					<el-col :span="8">
						<el-form-item label="检测名称：">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="检测时间：">
							<el-date-picker v-model="formData.time" type="date" value-format="yyyy-MM-dd"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div style="max-height: 500px;overflow: auto;">
				<div class="noneData" v-if="!formData.factories||formData.factories.length==0">
					<el-empty>
						<img slot="image" src="@assets/osImg/noneData.png" />
						<div slot="description">暂无数据</div>
					</el-empty>
				</div>
				<div class="tables" v-for="(item1,i1) in formData.factories " :key="i1">
					<div class="title">
						<span class="title-name" v-html="item1.factory"></span>
						<el-input style="display: inline-block;width: auto;" size="mini" v-model="item1.state">
						</el-input>
					</div>
					<div class="tables2" v-for="(item2,i2) in item1.workshops " :key="i2">
						<div class="title2" v-html="item2.name"></div>
						<div class="table">
							<el-table ref="usertable" :data="item2.oils" border max-height="300"
								:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
								style="margin-bottom: 20px;">
								<el-table-column prop="sort" align="center" label="序号" show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="name" align="center" label="油站名称" show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="type" align="center" label="油品型号" show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="pollutionClass" align="center" label="污染度等级NAS"
									show-overflow-tooltip>
									<template slot-scope="scope">
										<el-input v-model="scope.row.pollutionClass"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="standard" align="center" label="标准等级" show-overflow-tooltip>
								</el-table-column>
							</el-table>
						</div>
					</div>

				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">保存</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	import smCard from "@c/smCard.vue"
	export default {
		mixins: [dialogMixin],
		components: {
			smCard,
		},
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.get();
			},
			//获取数据
			get() {
				this.loading = true;
				this.$get("/backend-api/data/oil/detect/to-update", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.formData = res.data
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			submit() {
				this.loading = true;
				this.$postData("/backend-api/data/oil/detect/save-or-update", this.formData).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.list();
						this.close();
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 1200px;

		}
	}

	.tables {
		margin: 0 24px 10px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid #DBDBDB;
	}

	.tables2 {
		background: #FFFFFF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid #DBDBDB;
		margin: 12px 16px;
	}

	.title {
		height: 45px;
		color: #595959;
		font-size: 14px;
		padding: 10px 15px;
		background: #F0F0F0;

		.title-name {
			font-weight: bold;
			margin-right: 20px;
		}

		.title-remarks {}
	}

	.title2 {
		height: 45px;
		padding: 10px;
		font-size: 14px;
		font-weight: bold;
		color: #262626;
	}

	.table {
		margin: 0 12px 12px;
	}
</style>
